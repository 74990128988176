

import { ROOT_ACTIONS } from '@/store/actions';
import { Vue, Component, VModel } from 'vue-property-decorator';


import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { firestore, storage, } from '@/firebase';


@Component
export default class ConfirmationDialog extends Vue {
  @VModel({ required: true }) show!: boolean;


  inputs = {
    title: '',
    youtubeUrl: null
  }

  loading = false;

  type = 'VIDEO';

  async submit() {
    try {
      this.loading = true;
      
      if(this.inputs.title === '') {
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
          color: 'info',
          text: 'Gelieve een titel in te vullen'
        })
        return;
      }

      const doc = await addDoc(collection(firestore, 'content-items'), {
        title: this.inputs.title,
        type: this.type,
        archived: false,
        props: {
          youtubeUrl: this.inputs.youtubeUrl
        }
      })

      const fileRef = ref(storage, `content-items/${doc.id}`);

      if (this.type === 'VIDEO') {
        
        //@ts-expect-error Todo: proper checks
        const file = document.getElementById('video').files[0] as File;

        if (!file) {
          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'info',
            text: 'Gelieve een bestand te selecteren'
          })
          return;
        }

        await uploadBytes(fileRef, file);
      }

      if (this.type === 'PDF') {
        //@ts-expect-error Todo: proper checks
        const file = document.getElementById('pdf').files[0] as File;

        if (!file) {
          this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'info',
            text: 'Gelieve een bestand te selecteren'
          })
        }

        await uploadBytes(fileRef, file);
      }


      this.show = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Item aangemaakt'
      })

      this.$emit('refresh')

    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }
  }
}
